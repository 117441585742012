import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const [formData, setFormData] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setFormData(initialState);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, message } = formData;

    if (!name || !email || !message) {
      alert("All fields are required!");
      return;
    }

    emailjs.send("service_55yohe3", "template_of8ipfh", formData, "A6UvfqlaXRLq8w5uh").then(
      (result) => {
        console.log("Email sent successfully:", result.text);
        clearState(); // Reset the form
      }, (error) => {
        console.error("Error sending email:", error.text);
      }
    );
  };


  const redirectToWhatsApp = () => {
    const phoneNumber = "+917355322879"; // Your WhatsApp number here
    // +91 9931649141
    const message = `Hello, I am interested in your service`

    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    // Open the WhatsApp chat link in a new tab
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input type="text" id="name" name="name" className="form-control" placeholder="Name" required value={formData.name} onChange={handleChange} />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <input type="email" id="email" name="email" className="form-control" placeholder="Email" required value={formData.email} onChange={handleChange} />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <textarea name="message" id="message" className="form-control" rows="4" placeholder="Message" required value={formData.message} onChange={handleChange}></textarea>
                  <p className="help-block text-danger"></p>
                </div>

                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">Send Message</button>
              </form>
            </div>
          </div>

          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p onClick={redirectToWhatsApp} className="cursor-pointer" >
                <span>
                  <i className="fa fa-whatsapp"></i> WhatsApp
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"} target="_blank">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"} target="_blank">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"} target="_blank">
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.linkedin : "/"} target="_blank">
                      <i className="fa fa-linkedIn"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 SK Techrise{" "}<br />
            <a href="https://sktechrise.com" rel="nofollow">sktechrise.com</a>
          </p>
        </div>
      </div>
    </div >
  );
};
