import React from "react";

export const Navigation = (props) => {
  return (
    <nav style={{ backgroundColor: "", borderColor: "" }} id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          {/* Responsive Image */}
          <a className="navbar-brand" href="https://sktechrise.com/">
            <img src="/img/logosktech.png" alt="Logo" className="img-responsive" style={{ maxWidth: "200px", height: "auto" }} />
          </a>
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#features" style={{ color: "black", fontWeight: "bold" }} className="page-scroll ">
                Features
              </a>
            </li>
            <li>
              <a href="#about" style={{ color: "black", fontWeight: "bold" }} className="page-scroll ">
                About
              </a>
            </li>
            <li>
              <a href="#services" style={{ color: "black", fontWeight: "bold" }} className="page-scroll ">
                Services
              </a>
            </li>
            <li>
              <a href="#portfolio" style={{ color: "black", fontWeight: "bold" }} className="page-scroll ">
                Gallery
              </a>
            </li>
            <li>
              <a href="#testimonials" style={{ color: "black", fontWeight: "bold" }} className="page-scroll ">
                Testimonials
              </a>
            </li>
            <li>
              <a href="#team" style={{ color: "black", fontWeight: "bold" }} className="page-scroll ">
                Team
              </a>
            </li>
            <li>
              <a href="#contact" style={{ color: "black", fontWeight: "bold" }} className="page-scroll ">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
