import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>Our Services at SK Techrise create a digital environment that is inclusive and welcoming to all individuals. We are committed to ensuring equal access to online information and experiences beyond compliance.</p>
        </div>

        <div className="row">
          {props.data ? props.data.map((d, i) => (
            <div key={`${d.name}-${i}`} className="col-md-4">
              {" "}
              <i className={d.icon}></i>
              <div className="service-desc">
                <h3>{d.name}</h3>
                <p>{d.text}</p>
              </div>
            </div>
          ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
