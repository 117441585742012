import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      {/* <iframe  style={{ width: '100%', height: '50vh' }} src="https://www.youtube.com/embed/P4g1_g0ESOk?si=AdxuHEoNI6Fs9UEp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
      {/* <div className="ratio ratio-16x9">
        <iframe
          src="https://www.youtube.com/embed/P4g1_g0ESOk?si=AdxuHEoNI6Fs9UEp"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        >
        </iframe>
      </div> */}
      <div className="container">
        <div className="section-title">
          <h2 className="mt-10">Portfolio</h2>
          <p>Explore my collection of completed projects showcasing expertise in full-stack applications, Android app development, and WordPress websites. Highlights include dynamic web applications with robust backend systems, an intuitive Android app delivering seamless user experiences, and two visually stunning WordPress sites tailored to meet diverse client needs. Quality and innovation drive every project!</p>
        </div>
        {/* <div className="row">
          <div className="portfolio-items">
            {props.data ? props.data.map((d, i) => (
              <div key={`${d.title}-${i}`} className="col-sm-6 col-md-4 col-lg-4">
                <Image title={d.title} largeImage={d.largeImage} smallImage={d.smallImage} />
              </div>
            ))
              : "Loading..."}
          </div>
        </div> */}
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-sm-6 col-md-4 col-lg-4">
                  <div className="portfolio-image-wrapper">
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                </div>
              ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
